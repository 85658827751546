import React from 'react'
import { motion } from "framer-motion"
import Avatar from '@mui/material/Avatar'
import styles from '/styles/Home.module.scss'

export default function Home() {
  const fadeUpIn = {
    initial: { y:100, opacity: 0 },
    animate: { y:0,  opacity: 1 },
    exit: { opacity: 0, x: 0, y: -100 }
  }
  return (
    <div className={'container'}>
      
      <motion.main variants={fadeUpIn} initial="initial" animate="animate" transition={{ duration: 1 }} exit="exit" className={'main'}>
          <div className={[styles.flex, styles.row].join(' ')}>
            <div className={styles.avatar}>
              <Avatar alt="My photo" src="/avatar.JPG" sx={{ width: 200, height: 200 }}/>
            </div>
            <div className={styles.title_container}>
              <h1 className={styles.title}>
                <span>Hi, I&apos;m</span>
                <span>Kennice</span>
                <span>Fung</span>
              </h1>
            </div>
          </div>

          <div className={styles.tagline}><span>Software Engineer;</span><span>Full Stack Developer;</span><span>Problem Solver;</span></div>
      </motion.main>
    </div>
  )
}
